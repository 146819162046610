import { isDefined } from "@clipboard-health/util-ts";
import { IonPage } from "@ionic/react";
import { Stack } from "@mui/material";
import { REFER_AND_EARN_WITH_ACCOUNTS_PATH } from "@src/appV2/Accounts/ReferAndEarn/paths";
import { useAgentProfile } from "@src/appV2/Agents/api/useAgentProfile";
import { DeprecatedGlobalAppV1Paths } from "@src/appV2/App/paths";
import { AppBarHeader, BackButtonLink, PageWithHeader, useToast } from "@src/appV2/lib";
import { APP_V2_USER_EVENTS, logEvent } from "@src/appV2/lib/analytics";

import { useReferralCode } from "./api/useReferralCode";
import {
  BonusBreakdown,
  ReferralCardSkeleton,
  ReferralGuide,
  ReferralPolicy,
  ReferralStats,
  ShareReferralCode,
  ShareReferralLinkCard,
} from "./components";
import { PromoteYourProfile } from "./components/PromoteYourProfile/PromoteYourProfile";
import { WORKPLACE_AFFILIATE_SCREEN_NAME, WORKPLACE_REFERRAL_SCREEN_NAME } from "./constants";
import { useWorkerToWorkplaceAffiliateExperiment } from "./hooks/useWorkerToWorkplaceAffiliateExperiment";
import { WorkplaceReferralAction, WorkplaceReferralComponent } from "./types";
import { getLatestReferralCode } from "./utils";
import { getWorkerToWorkplaceVariantName } from "./utils/getWorkerToWorkplaceVariantName";

export function WorkplaceReferralsPage() {
  const { showErrorToast } = useToast();
  const isWorkerToWorkplaceAffiliateProgramEnabled = useWorkerToWorkplaceAffiliateExperiment();
  const {
    data: agentProfileData,
    isLoading: isLoadingAgentProfile,
    isSuccess: isAgentProfileSuccess,
  } = useAgentProfile();

  const {
    data: referralCodeData,
    isLoading: isLoadingReferralCode,
    isSuccess: isReferralCodeSuccess,
  } = useReferralCode(
    {
      workerId: agentProfileData?.userId ?? "",
    },
    {
      enabled: isAgentProfileSuccess && isDefined(agentProfileData.userId),
      onError: () => {
        showErrorToast("Something went wrong while fetching the referral code");
      },
    }
  );
  const referralCode = getLatestReferralCode(referralCodeData?.referralCodes ?? []);

  return (
    <IonPage>
      <PageWithHeader
        appBarHeader={
          <AppBarHeader
            title={
              isWorkerToWorkplaceAffiliateProgramEnabled
                ? WORKPLACE_AFFILIATE_SCREEN_NAME
                : WORKPLACE_REFERRAL_SCREEN_NAME
            }
            leftCta={
              <BackButtonLink
                defaultBackTo={
                  isWorkerToWorkplaceAffiliateProgramEnabled
                    ? DeprecatedGlobalAppV1Paths.ACCOUNT
                    : REFER_AND_EARN_WITH_ACCOUNTS_PATH
                }
                onClick={() => {
                  logEvent(APP_V2_USER_EVENTS.WORKPLACE_REFERRAL, {
                    action: WorkplaceReferralAction.BACK_BUTTON_CLICKED,
                    component: WorkplaceReferralComponent.WORKPLACE_REFERRAL_PAGE,
                    variant: getWorkerToWorkplaceVariantName(
                      isWorkerToWorkplaceAffiliateProgramEnabled
                    ),
                    referralCode,
                  });
                }}
              />
            }
          />
        }
        containerMaxWidth="md"
      >
        {(isLoadingAgentProfile || isLoadingReferralCode) && (
          <ReferralCardSkeleton count={4} height={{ body: 240 }} />
        )}
        {isAgentProfileSuccess && isReferralCodeSuccess && referralCode && (
          <Stack direction="column" spacing={2}>
            <BonusBreakdown />
            <ShareReferralLinkCard referralCode={referralCode} />
            {!isWorkerToWorkplaceAffiliateProgramEnabled && (
              <>
                <ShareReferralCode referralCode={referralCode} />
                <PromoteYourProfile agent={agentProfileData} referralCode={referralCode} />
              </>
            )}
            <ReferralGuide referralCode={referralCode} />
            <ReferralStats agentId={agentProfileData.userId} />
            <ReferralPolicy referralCode={referralCode} />
          </Stack>
        )}
      </PageWithHeader>
    </IonPage>
  );
}
